<template>
  <div :class="$vuetify.breakpoint.mobile? 'pa-6' : 'pa-12'" class="secondary">
    <v-card
        class="mx-auto"
        width="85vw"
    >
      <v-row class="pa-6">
        <v-col cols="12" lg="4" md="4">
          <v-row align="center" class="my-6" justify="center">
            <v-avatar size="95%">
              <img :src="baseURL + '/storage/pfps/' + user.usersPfp" alt="Profile Picture"/>
            </v-avatar>
          </v-row>
          <v-btn :disabled="user.usersWebsite === 'null' || user.usersWebsite === null || user.usersWebsite === 'undefined' || user.usersWebsite === undefined" block
                 class="pa-6" color="primary"
                 @click="goToWebsite">
            <v-icon>mdi-search-web</v-icon>
            Website
          </v-btn>
          <v-btn :disabled="user.usersFacebook === 'null' || user.usersFacebook === null || user.usersFacebook === 'undefined' || user.usersFacebook === undefined" block
                 class="my-4 pa-6"
                 color="primary" @click="goToFacebook"
          >
            <v-icon>mdi-facebook</v-icon>
            Facebook
          </v-btn
          >
        </v-col>
        <v-col>
          <h1 :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-2'"
              class="primary--text text-h2 font-weight-bold text-uppercase">
            {{ user.usersUid }}
          </h1>
          <h5 class="text-h6 my-3">Member Since: {{ userDate }}</h5>
          <v-row>
            <v-col v-for="s in segments" cols="12" md="6" lg="6">
              <v-card height="125" outlined>
                <v-card-title>{{ s.key }}</v-card-title>
                <v-card-text v-if="s.key === 'Phone'" :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h6'"><a :href="'tel:' + s.value">{{ s.value }}</a></v-card-text>
                <v-card-text v-else :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h6'">{{ s.value }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card class="mt-6" height="125" outlined>
            <v-card-title>Description</v-card-title>
            <v-card-text :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h6'" class="text-break">
              {{ user.usersDesc }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ProfileInfo",
  metaInfo: {
    title: "Profile",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  props: ["uid"],
  data() {
    return {
      loaded: false,
      user: {},
      userParts: [],
    };
  },
  computed: {
    ...mapState(["baseURL"]),
    location() {
      if (
          this.user.userAddress &&
          this.user.userCity &&
          this.user.userPostcode &&
          this.user.userState
      ) {
        return this.user.userAddress +
            ", " +
            this.user.userCity +
            ", " +
            this.user.userPostcode +
            ", " +
            this.user.userState;
      } else {
        return "Unknown";
      }
    },
    segments() {
      let tierWord = ""
      switch (this.user.usersTier) {
        case 0:
          tierWord = "Private Seller"
          break;
        case 1:
          tierWord = "Micro Business"
          break;
        case 2:
          tierWord = "Small Business"
          break;
        case 3:
          tierWord = "Medium Business"
          break;
        case 4:
          tierWord = "Large Business"
          break;
        default:
          tierWord = "Error"
          break;
      }
      // Check if user has email set to unknown if empty, null or undefined
      let email = this.user.usersEmail
      if (email === "null" || email === null || email === "undefined" || email === undefined || email === "") {
        email = "Unknown"
      }
      // Check if user has phone set to unknown if empty, null or undefined
      let phone = this.user.usersPhone
      if (phone === "null" || phone === null || phone === "undefined" || phone === undefined || phone === "") {
        phone = "Unknown"
      }
      return [
        {key: 'Email', value: email},
        {key: 'Phone', value: phone},
        {key: 'Location', value: this.location},
        {key: 'Tier', value: tierWord},
      ]
    },
    userDate() {
      if (this.loaded) {
        return this.user.usersAccTime.slice(0, 10)
      }
    }
  },
  mounted() {
    this.getUserInfo();
  },
  watch: {
    uid() {
      this.getUserInfo();
    },
  },
  methods: {
    ...mapActions("data", ["postData"]),
    goToWebsite() {
      window.open(this.user.usersWebsite, 'newwindow');
    },
    goToFacebook() {
      window.open(this.user.usersFacebook, 'newwindow');
    },
    async getUserInfo() {
      let response = await this.postData({
        url: "fetchUser",
        data: {usersID: this.uid},
      });
      if (response["error"] === "none") {
        this.loaded = true;
        this.user = response;
      } else if (response["error"] !== "userNotFound") {
        await this.$router.push({name: "NotFound"});
      } else if (response["error"] !== "none") {
        console.log(response["error"]);
      }
    },
  },
};
</script>