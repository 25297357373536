<template>
  <div>
    <!-- Mobile -->
    <v-navigation-drawer
        v-model="drawer"
        :permanent="$route.name === 'Dashboard' && !$vuetify.breakpoint.mobile"
        :temporary="$route.name !== 'Dashboard' || $vuetify.breakpoint.mobile"
        app
        width="350px"
    >
      <v-list v-if="loggedIn">
        <v-list-item class="px-2">
          <v-list-item-avatar
              v-if="currentUser.usersPfp"
              :size="$vuetify.breakpoint.mobile ? 50 : 100"
          >
            <v-img v-if="currentUser.usersPfp" :src="pfp"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.usersUid }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
                currentUser.usersEmail
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider v-if="loggedIn"></v-divider>

      <v-list dense nav>
        <v-list-item-group color="primary">
          <v-list-item
              v-for="(link, i) in filteredLinks"
              :key="i"
              class="pa-md-4 pa-sm-auto pa-xs-auto"
              link
              @click="handleNavClick(link)"
          >
            <v-list-item-icon class="mt-3">
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                  class="text-h6 pa-2"
                  v-text="link.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-if="loggedIn"
              class="pa-md-4 pa-sm-auto pa-xs-auto"
              link
              @click="
              $store.dispatch('user/logoutUser');
              $router.push({ name: 'Index' });
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6 pa-2">Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat>
      <v-btn
          v-show="$route.name !== 'Dashboard' || $vuetify.breakpoint.mobile"
          :class="$vuetify.breakpoint.mobile ? 'ml-n3' : 'mx-4'"
          class="rounded-sm"
          text
          @click="drawer = !drawer"
      >
        <v-icon class="mr-1">mdi-menu</v-icon>
        MENU
      </v-btn
      >
      <v-toolbar-title class="primary--text font-weight-bold">
        <div
            :class="$vuetify.breakpoint.mobile ? '' : 'ml-2'"
            class="d-inline-flex justify-center pointerG"
            @click="$router.push({ name: 'Index' })"
        >
          <img
              :height="$vuetify.breakpoint.mobile ? '25' : '45'"
              :width="$vuetify.breakpoint.mobile ? '25' : '45'"
              alt="SellMyCarParts.com"
              src="@/assets/logo.svg"
          />
          <h4
              :class="$vuetify.breakpoint.mobile ? 'text-h6' : 'mt-2 text-h5'"
              class="text-h5 primary--text font-weight-bold"
          >
            SellMyCarParts.com.au
          </h4>
        </div>
      </v-toolbar-title>
      <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
      <div v-if="!$vuetify.breakpoint.mobile">
        <v-btn
            v-if="loggedIn"
            class="mr-2 rounded-sm"
            elevation="1"
            @click="$router.push({ name: 'Dashboard' })"
        >
          <v-icon>mdi-view-dashboard</v-icon>
          Dashboard
        </v-btn>
        <v-btn class="mr-2" @click="$router.push({ name: 'Market' })">
          <v-icon>mdi-store</v-icon>
          Search
        </v-btn>
        <v-btn
            v-if="!loggedIn"
            class="mr-2 rounded-sm"
            elevation="1"
            @click="$router.push({ name: 'SignUp' })"
        >
          <v-icon>mdi-account-plus</v-icon>
          Signup
        </v-btn>
        <v-btn
            v-if="!loggedIn"
            :to="{ name: 'Login' }"
            class="rounded-sm"
            elevation="1">
          <v-icon>mdi-login</v-icon>
          Login
        </v-btn>
        <v-btn
            v-if="loggedIn"
            class="rounded-sm"
            elevation="1"
            @click="
            $store.dispatch('user/logoutUser');
            $router.push({ name: 'Index' });
          "
        >
          <v-icon>mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      drawer: false,
      links: [
        {
          title: "Search",
          action: {name: "Market", params: {}},
          icon: "mdi-store",
          log: 0,
        },
        {
          title: "Dashboard",
          action: {name: "Dashboard", params: {}},
          icon: "mdi-view-dashboard",
          log: 1,
        },
        {
          title: "Categories",
          action: {name: "Index", params: {}},
          icon: "mdi-filter-variant",
          log: 0,
        },
        {
          title: "Profile",
          action: {
            name: "Profile",
            params: {},
          },
          icon: "mdi-account",
          log: 1,
        },
        {
          title: "Sell a part",
          action: {name: "NewPart", params: {}},
          icon: "mdi-plus",
          log: 1,
        },
        {
          title: "My Stuff",
          action: {name: "PartsList", params: {}},
          icon: "mdi-playlist-edit",
          log: 1,
        },
        {
          title: "Account",
          action: {name: "Account", params: {}},
          icon: "mdi-cog",
          log: 1,
        },
        {
          title: "Subscriptions",
          action: {name: "Subscriptions", params: {}},
          icon: "mdi-star",
          log: 0,
        },
        {
          title: "About Us",
          action: {name: "AboutUs", params: {}},
          icon: "mdi-help",
          log: 0,
        },
        {
          title: "Create Account",
          action: {name: "SignUp", params: {}},
          icon: "mdi-account-plus",
          log: 2,
        },
        {
          title: "Login",
          action: {name: "Login", params: {}},
          icon: "mdi-login",
          log: 2,
        },
      ],
    };
  },
  computed: {
    ...mapState('user', ['currentUser', 'loggedIn']),
    ...mapState(["baseURL"]),
    filteredLinks() {
      return this.links.filter((link) => {
        if (this.loggedIn) {
          return link.log === 0 || link.log === 1;
        } else {
          return link.log === 0 || link.log === 2;
        }
      });
    },
    pfp: {
      get() {
        return this.baseURL + "/storage/pfps/" + this.currentUser.usersPfp;
      },
      set(value) {
        return this.baseURL + "/storage/pfps/" + this.currentUser.usersPfp;
      }

    }
  },
  methods: {
    handleNavClick(link) {
      if (
          this.$route.name === link.action.name &&
          JSON.stringify(this.$route.params) === JSON.stringify(link.action.params)
      ) {
        this.$router.go();
      } else {
        this.$router.push(link.action);
      }
    }
  },
  watch: {
    "this.currentUser": {
      immediate: true,
      handler() {
        this.links[3]["action"]["params"] = {
          uid: this.currentUser.usersUid,
        };
      },
    },
  },
  mounted() {
    this.pfp =
        this.baseURL +
        "/storage/pfps/" +
        this.currentUser.usersPfp;
    // Filter through links and set the params for the profile link
    this.links[3]["action"]["params"] = {
      uid: this.currentUser.usersUid,
    };
  },
};
</script>
