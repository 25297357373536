import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        //baseURL: "http://192.168.1.5:8081"
        baseURL: "https://www.sellmycarparts.com.au",
    },
    mutations: {},
    actions: {},
    modules,
});
