var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.mobile)?_c('v-card',{attrs:{"color":"secondary pa-6"}},[_c('h4',{staticClass:"text-h4 white--text mt-2"},[_vm._v("View")]),_c('h3',{staticClass:"text-h3 primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.title))]),(_vm.parts.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[0]['partsID'],
            partsName: _vm.parts[0]['partsName']
          },"height":"150px"}}),_c('PartIcon',{attrs:{"part":{
            partsID: _vm.parts[3]['partsID'],
            partsName: _vm.parts[3]['partsName']
          },"height":"150px"}}),_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[2]['partsID'],
            partsName: _vm.parts[2]['partsName']
          },"height":"150px"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[1]['partsID'],
            partsName: _vm.parts[1]['partsName']
          },"height":"150px"}}),_c('PartIcon',{attrs:{"part":{
            partsID: _vm.parts[4]['partsID'],
            partsName: _vm.parts[4]['partsName']
          },"height":"150px"}}),(!_vm.disabledMore)?_c('v-btn',{staticClass:"primary--text my-4",attrs:{"height":"150px","width":"100%"},on:{"click":function($event){return _vm.$router.push(_vm.push)}}},[_vm._v("View more "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1):_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[5]['partsID'],
            partsName: _vm.parts[5]['partsName']
          },"height":"150px"}})],1)],1):_c('v-row',{staticClass:"fill-height"},[_c('h5',{staticClass:"text-h5 white--text my-6"},[_vm._v("No Items listed")])])],1):_c('v-card',{staticClass:"pa-6",attrs:{"color":"secondary"}},[_c('h4',{staticClass:"text-h4 white--text mt-2"},[_vm._v(" View "),_c('span',{staticClass:"text-h3 primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.title))])]),(_vm.loaded === 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[0]['partsID'],
            partsName: _vm.parts[0]['partsName']
          },"height":"150px"}}),_c('PartIcon',{attrs:{"part":{
            partsID: _vm.parts[3]['partsID'],
            partsName: _vm.parts[3]['partsName']
          },"height":"150px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[1]['partsID'],
            partsName: _vm.parts[1]['partsName']
          },"height":"150px"}}),_c('PartIcon',{attrs:{"part":{
            partsID: _vm.parts[4]['partsID'],
            partsName: _vm.parts[4]['partsName']
          },"height":"150px"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('PartIcon',{staticClass:"my-4",attrs:{"part":{
            partsID: _vm.parts[2]['partsID'],
            partsName: _vm.parts[2]['partsName']
          },"height":"150px"}}),(!_vm.disabledMore)?_c('v-btn',{staticClass:"primary--text",attrs:{"block":"","height":"150px"},on:{"click":function($event){return _vm.$router.push(_vm.push)}}},[_vm._v("View more "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1):_c('PartIcon',{attrs:{"part":{
            partsID: _vm.parts[5]['partsID'],
            partsName: _vm.parts[5]['partsName']
          },"height":"150px"}})],1)],1):(_vm.loaded === 0)?_c('v-row',[_c('v-col',[_c('Loader',{attrs:{"size":75}})],1)],1):_c('v-row',[_c('v-col',[_c('h4',{staticClass:"text-h4 white--text my-6"},[_vm._v("An error has occurred, try refreshing the page")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }