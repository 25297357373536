<template>
  <v-alert
      v-if="alertOn"
      :class="'pointerG' + !$vuetify.breakpoint.mobile ? 'ma-4' : ''"
      :type="alertType"
      dismissible
      @click="$store.dispatch('alert/offAlert')"
  >{{ alertMsg }}
  </v-alert
  >
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed: mapState('alert', ["alertType", "alertMsg", "alertOn"]),
}
</script>