<template>
  <div>
    <!-- Mobile -->
    <v-card v-if="$vuetify.breakpoint.mobile" color="secondary pa-6">
      <h4 class="text-h4 white--text mt-2">View</h4>
      <h3 class="text-h3 primary--text font-weight-bold">{{ title }}</h3>
      <v-row v-if="parts.length > 0">
        <v-col cols="6">
          <PartIcon
              :part="{
              partsID: parts[0]['partsID'],
              partsName: parts[0]['partsName']
            }"
              class="my-4"
              height="150px"
          />
          <PartIcon
              :part="{
              partsID: parts[3]['partsID'],
              partsName: parts[3]['partsName']
            }"
              height="150px"
          />
          <PartIcon
              :part="{
              partsID: parts[2]['partsID'],
              partsName: parts[2]['partsName']
            }"
              class="my-4"
              height="150px"
          />
        </v-col>
        <v-col cols="6">
          <PartIcon
              :part="{
              partsID: parts[1]['partsID'],
              partsName: parts[1]['partsName']
            }"
              class="my-4"
              height="150px"
          />
          <PartIcon
              :part="{
              partsID: parts[4]['partsID'],
              partsName: parts[4]['partsName']
            }"
              height="150px"
          />
          <v-btn
              v-if="!disabledMore"
              class="primary--text my-4"
              height="150px"
              width="100%"
              @click="$router.push(push)"
          >View more
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn
          >
          <PartIcon
              v-else
              :part="{
              partsID: parts[5]['partsID'],
              partsName: parts[5]['partsName']
            }"
              class="my-4"
              height="150px"
          />
        </v-col>
      </v-row>
      <v-row v-else class="fill-height">
        <h5 class="text-h5 white--text my-6">No Items listed</h5>
      </v-row>
    </v-card>

    <!-- Desktop -->
    <v-card v-else class="pa-6" color="secondary">
      <h4 class="text-h4 white--text mt-2">
        View
        <span class="text-h3 primary--text font-weight-bold">{{ title }}</span>
      </h4>
      <v-row v-if="loaded === 1">
        <v-col cols="4">
          <PartIcon
              :part="{
              partsID: parts[0]['partsID'],
              partsName: parts[0]['partsName']
            }"
              class="my-4"
              height="150px"
          />
          <PartIcon
              :part="{
              partsID: parts[3]['partsID'],
              partsName: parts[3]['partsName']
            }"
              height="150px"
          />
        </v-col>
        <v-col cols="4">
          <PartIcon
              :part="{
              partsID: parts[1]['partsID'],
              partsName: parts[1]['partsName']
            }"
              class="my-4"
              height="150px"
          />
          <PartIcon
              :part="{
              partsID: parts[4]['partsID'],
              partsName: parts[4]['partsName']
            }"
              height="150px"
          />
        </v-col>
        <v-col cols="4">
          <PartIcon
              :part="{
              partsID: parts[2]['partsID'],
              partsName: parts[2]['partsName']
            }"
              class="my-4"
              height="150px"
          />
          <v-btn
              v-if="!disabledMore"
              block
              class="primary--text"
              height="150px"
              @click="$router.push(push)"
          >View more
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn
          >
          <PartIcon
              v-else
              :part="{
              partsID: parts[5]['partsID'],
              partsName: parts[5]['partsName']
            }"
              height="150px"
          />
        </v-col>
      </v-row>
      <v-row v-else-if="loaded === 0">
        <v-col>
          <Loader :size="75"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <h4 class="text-h4 white--text my-6">An error has occurred, try refreshing the page</h4>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import PartIcon from "@/components/landing/PartIcon.vue";
import {mapActions} from 'vuex';

export default {
  components: {PartIcon, Loader},
  props: ["title", "type", "push", "disabledMore"],
  data() {
    return {
      parts: [],
      loaded: 0
    };
  },
  mounted() {
    this.fetchParts();
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async fetchParts() {
      let response = await this.postData({
        url: this.type,
        data: {start: 0, limit: 6},
      });
      try {
        response["parts"].forEach((element) => {
          this.parts.push(element);
        });
        this.loaded = 1;
      } catch (error) {
        console.log("No Parts found: " + error);
        this.loaded = 2;
      }
    },
  },
};
</script>