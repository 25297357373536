<template>
  <div class="d-flex-column justify-center align-center text-center my-16">
    <h5 class="text-h2 my-6 primary--text font-weight-medium">
      Webpage Not Found 404
    </h5>
    <h5 class="text-h5 mb-10">
      It seems the page you were looking for was not found! Double check the URL
      address or
    </h5>
    <v-btn :to="{ name: 'Index' }" color="primary" height="50px" width="250px"
    >Return to homepage
    </v-btn
    >
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "404 Not Found",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
};
</script>