<template>
  <div>
    <v-row class="pa-6">
      <v-col>
        <h3>Reviews: (Coming Soon)</h3>
        <v-rating
            :size="!$vuetify.breakpoint.mobile ? '64' : '31'"
            length="5"
            readonly
            :value="3"
        ></v-rating>
      </v-col>
      <v-col>
        <v-btn
            :style="!$vuetify.breakpoint.mobile ? 'float: right' : 'float: left'"
            class="mt-8"
            color="primary"
            disabled
        >Leave a Review
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "UserRatings"
}
</script>

<style scoped>

</style>