import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {
        currentUser: {},
        loggedIn: false,
    },
    mutations: {
        SET_CURRENT_USER(state, user) {
            if (user !== "{}") {
                state.currentUser = user;
                state.loggedIn = true;
                localStorage.setItem("currentUser", JSON.stringify(user));
            }
        },
        LOGOUT(state) {
            state.currentUser = {};
            state.loggedIn = false;
            localStorage.setItem("currentUser", JSON.stringify({}));
        },
        CHANGE_PFP(state, pfp) {
            state.currentUser.usersPfp = pfp;
            localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
        },
        CHANGE_TIER(state, tier) {
            state.currentUser.usersTier = tier;
            localStorage.setItem("currentUser", JSON.stringify(state.currentUser));
        },
    },
    actions: {
        async registerUser({commit, rootState}, formObj) {
            let formData = new FormData();
            for (let element in formObj) {
                formData.append(element, formObj[element]);
            }
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/registerUser.inc.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            let user = response.data;
            if (response.data["error"] !== "none") {
                return response.data;
            } else {
                delete user["error"];
                commit("SET_CURRENT_USER", user);
                return response.data;
            }
        },
        async loginUser({commit, rootState}, formObj) {
            // console.log(formObj.email);
            // console.log(formObj.password);
            let formData = new FormData();
            for (let element in formObj) {
                formData.append(element, formObj[element]);
            }
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/loginUser.inc.php",
                    formData
                )
                .catch((e) => {
                    console.log(e);
                });
            let user = response.data;
            if (response.data["error"] !== "none") {
                return response.data;
            } else {
                delete user["error"];
                commit("SET_CURRENT_USER", user);
                return response.data;
            }
        },
        async loadUser({state, commit, rootState}) {
            try {
                let localUser = JSON.parse(localStorage.getItem("currentUser"));
                if (Object.entries(localUser).length !== 0) {
                    commit(
                        "SET_CURRENT_USER",
                        JSON.parse(localStorage.getItem("currentUser"))
                    );
                    let formData = new FormData();
                    formData.append("usersID", state.currentUser.usersID)
                    let response = await axios
                        .post(
                            rootState.baseURL + "/php/data/fetch/fetchUserTier.inc.php",
                            formData
                        )
                        .catch((e) => {
                            console.log(e);
                        });
                    if (response.data["error"] !== "none") {
                        return response.data;
                    } else {
                        commit("CHANGE_TIER", response.data.usersTier);
                    }
                }

            } catch (e) {
                console.log("Error Loading Stored User (First Time): " + e)
            }
        },
        logoutUser({commit}) {
            commit("LOGOUT");
        },
        async editUser({commit, state, rootState}, formObj) {
            let formData = new FormData();
            formData.append("usersID", state.currentUser.usersID);
            for (let element in formObj) {
                formData.append(element, formObj[element]);
            }
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/editUser.inc.php",
                    formData
                )
                .catch((e) => {
                    console.log(e);
                });
            if (response.data["error"] !== "none") {
                return response.data;
            } else {
                commit("SET_CURRENT_USER", response.data["user"]);
                return response.data;
            }
        },
        async getUser({commit, state, rootState}) {
            let formData = new FormData();
            formData.append("usersID", state.currentUser.usersID);
            formData.append("pwd", state.currentUser.usersPwd)
            let response = await axios.post(
                rootState.baseURL + "/php/users/getUser.inc.php",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            ).catch((e) => {
                console.log(e);
            });
            if (response.data["error"] === "none") {
                commit("SET_CURRENT_USER", response.data["user"]);
            }
        },
        async changePfp({commit, rootState}, formObj) {
            let formData = new FormData();
            formData.append("usersID", formObj["usersID"]);
            formData.append(0, formObj["image"]);
            formData.append("tags", formObj["tags"]);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/pfpUpload.inc.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            commit("CHANGE_PFP", response.data["pfp"]);
            return response.data;
        },
        async pwdCheck({commit, state, rootState}, partsID) {
            // Parts ID is more of a formality for PartEdit.vue to clean the code
            let formData = new FormData();
            // console.log(state.currentUser);
            // console.log(state.currentUser.usersID);
            // console.log(state.currentUser.usersPwd)
            formData.append("usersID", state.currentUser.usersID);
            formData.append("pwd", state.currentUser.usersPwd);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/pwdCheck.inc.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
        async ownerCheck({commit, state, rootState}, partsID) {
            let formData = new FormData();
            // console.log(state.currentUser);
            //console.log(state.currentUser.usersID);
            //console.log(partsID);
            formData.append("usersID", state.currentUser.usersID);
            formData.append("partsID", partsID);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/ownerCheck.inc.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
        async report({commit, rootState}, formObj) {
            console.log({
                formObj,
            });
            let formData = new FormData();
            for (let element in formObj) {
                formData.append(element, formObj[element]);
            }
            let response = await axios
                .post(
                    rootState.baseURL + "/php/users/report.inc.php",
                    formData
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
    },
};
