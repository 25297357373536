<template>
  <div>
    <v-row
        :class="$vuetify.breakpoint.mobile ? 'pa-4' : 'py-16'"
        class="secondary mt-n2"
        no-gutters
    >
      <v-col v-if="!$vuetify.breakpoint.mobile"/>
      <v-col :cols="!$vuetify.breakpoint.mobile ? 10 : 12" class="py-4">
        <ViewCategories/>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile"/>
    </v-row>
    <v-row no-gutters>
      <v-col/>
      <v-col cols="10">
        <v-row class="mt-2 mb-6">
          <v-col cols="12" lg="6" md="6">
            <SearchViewA
                :class="$vuetify.breakpoint.mobile ? 'mb-6' : ''"
                :push="{ name: 'Market', query: {} }"
                title="Latest"
                type="fetchNewParts"
            />
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <SearchViewA
                :disabledMore="true"
                title="Featured"
                type="fetchFeaParts"
            />
          </v-col>
        </v-row>
        <SearchViewB
            class="mb-12"
            title="Sponsored"
            :push="{ name: 'Sponsors' }"
            type="fetchSpoUsersRand"
        />
      </v-col>
      <v-col/>
    </v-row>
  </div>
</template>

<script>
import ViewCategories from "@/components/ViewCategories.vue";
import SearchViewA from "@/components/landing/SearchViewA.vue";
import SearchViewB from "@/components/landing/SearchViewB.vue";

export default {
  metaInfo: {
    title: "SellMyCarParts",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  components: {SearchViewA, SearchViewB, ViewCategories},
  data() {
    return {};
  },
};
</script>
