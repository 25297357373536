<template>
  <div>
    <ProfileInfo :uid="$route.params.uid"/>
<!--    <UserRatings />-->
    <OtherParts :uid="$route.params.uid"/>


  </div>
</template>

<script>
import OtherParts from "@/components/Profile/OtherParts";
import ProfileInfo from "@/components/Profile/ProfileInfo";
import UserRatings from "@/components/Profile/UserRatings";

export default {
  components: {UserRatings, ProfileInfo, OtherParts},
  name: "Profile",
  metaInfo: {
    title: "Profile",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  }
};
</script>