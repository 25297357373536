<template>
  <v-btn
      block
      color="primary"
      @click="$router.push({ name: 'Profile', params: { uid: usersUid } })"
  >
    Contact Seller
  </v-btn>
</template>

<script>
export default {
  props: ["usersUid"],
};
</script>