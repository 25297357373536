<template>
  <v-card
      :width="$vuetify.breakpoint.mobile ? '95%' : ''"
      class="mx-auto pa-6 my-4 rounded-0"
      color="secondary"
  >
    <h4 class="text-h4 white--text">View by</h4>
    <h3 class="text-h3 primary--text font-weight-bold mb-6">
      User's Other Parts
    </h3>
    <v-row>
      <v-col v-for="part in userParts" :key="part.partsID" cols="12" lg="4" md="4">
        <UserPartCard :item="part" />
      </v-col>
    </v-row>
    <v-btn v-if="!reachedMax" block color="primary" class="mt-6" @click="getUserParts">Load More Parts</v-btn>
    <h5 v-else class="text-h5 white--text">All parts have been loaded</h5>
  </v-card>
</template>

<script>
import Loader from "@/components/Loader";
import {mapActions} from "vuex";
import UserPartCard from "@/components/UserPartCard";

export default {
  name: 'OtherParts',
  components: {UserPartCard, Loader},
  props: ["uid"],
  data: () => ({
    reachedMax: false,
    loaded: false,
    userParts: [],
    incrementBy: 12,
    start: 0,
    limit: 0,
  }),
  mounted() {
    this.limit = this.incrementBy;
    this.getUserParts();
  },
  methods: {
    ...mapActions("data", ["postData"]),
    ...mapActions("alert", ["alerts"]),
    async getUserParts() {
      let response = await this.postData({
        url: "fetchUserParts",
        data: {usersID: this.uid, start: this.start, limit: this.limit},
      });
      if (response["error"] === "none") {
        if (response["parts"] !== "none") {
          this.userParts = this.userParts.concat(response["parts"]);
          this.start += this.incrementBy;
          this.limit += this.incrementBy;
        } else {
          this.reachedMax = true;
        }
      } else if (response["error"] !== "none") {
        console.error("Error: " + response["error"]);
        await this.alerts({
          type: "error",
          msg: "We had trouble finding some of the parts you were looking for",
        });
      }
    },
  }
}
</script>