import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#F74E4F",
                secondary: "#30424B",
                accent: "#C1C1C7",
            },
            dark: {
                primary: "#F74E4F",
                secondary: "#C1C1C7",
                accent: "#30424B",
            },
        },
    },
    icons: {
        iconfont: "mdi",
    },
});
