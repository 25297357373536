import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import Market from "../views/Market.vue";
import NotFound from "../views/NotFound.vue";
import Part from "../views/Part.vue";
import Profile from "../views/Profile.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/home",
        name: "Index",
        component: Index,
    },
    {
        path: "/",
        alias: "/market",
        name: "Market",
        component: Market,
    },
    {
        path: "/account",
        name: "Account",
        component: () => import("../views/Account.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue"),
    },
    {
        path: "/profile/:uid",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/signup",
        name: "SignUp",
        component: () => import("../views/SignUp.vue"),
    },
    {
        path: "/new-part",
        name: "NewPart",
        component: () => import("../views/NewPart.vue"),
    },
    {
        path: "/edit-part/:id",
        name: "PartEdit",
        component: () => import("../views/PartEdit.vue"),
    },
    {
        path: "/part/",
        name: "Part",
        component: Part,
    },
    {
        path: "/part-list",
        name: "PartsList",
        component: () => import("../views/PartsList.vue"),
    },
    {
        path: "/search/",
        name: "Search",
        component: () => import("../views/Search.vue"),
        props: true,
    },
    {
        path: "/subscriptions",
        name: "Subscriptions",
        component: () => import("../views/Subscriptions.vue"),
    },
    {
        path: "/subscriptions/summary",
        name: "SubSummary",
        component: () => import("../views/SubSummary.vue"),
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: () => import("../views/AboutUs.vue"),
    },
    {
        path: "/acknowledgments",
        name: "Acknowledgments",
        component: () => import("../views/Acknowledgments.vue"),
    },
    {
        path: "/sponsors",
        name: "Sponsors",
        component: () => import("../views/Sponsors.vue"),
    },
    // Catch All 404
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    root: "/",
    base: "/",
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
