<template>
  <div>
    <!-- Desktop View -->
    <v-row
        v-show="!$vuetify.breakpoint.mobile"
        class="pa-4 secondary backgroundImg"
        no-gutters
    >
      <v-col></v-col>
      <v-col cols="9">
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="searchAll"
                  append-icon="mdi-magnify"
                  class="rounded-pill"
                  label="Search all"
                  solo
                  @click:append="getSearchInfo"
              />
              <v-row>
                <v-col>
                  <v-combobox
                      v-model="searchInfo.brand"
                      :items="brandItems"
                      class="rounded-pill"
                      label="Vehicle Manufacturer"
                      placeholder="Vehicle Manufacturer"
                      solo
                  />
                  <v-combobox
                      v-model="searchInfo.model"
                      :items="modelItems"
                      class="rounded-pill"
                      label="Model"
                      placeholder="Model"
                      solo
                  />
                  <v-select
                      v-model="searchInfo.tags"
                      :items="tagItems"
                      class="rounded-pill"
                      label="Category"
                      solo
                  />
                  <v-select
                      v-show="showMore"
                      v-model="searchInfo.type"
                      :items="types"
                      class="rounded-pill"
                      label="Type"
                      placeholder="Type"
                      solo
                  />
                  <v-combobox
                      v-show="showMore"
                      v-model="searchInfo.maker"
                      :items="manufacturerItems"
                      class="rounded-pill"
                      label="Item Manufacturer"
                      placeholder="Item Manufacturer"
                      solo
                  />

                </v-col>
                <v-col>
                  <v-select
                      v-model="searchInfo.year"
                      :items="getYears"
                      class="rounded-pill"
                      label="Year"
                      placeholder="Year"
                      solo
                  />
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field
                          v-model="priceMin"
                          class="rounded-pill mr-1"
                          label="$ Min"
                          placeholder="$ Min"
                          solo
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="priceMax"
                          class="rounded-pill ml-1"
                          label="$ Max"
                          placeholder="$ Max"
                          solo
                      />
                    </v-col>
                  </v-row>
                  <v-select
                      v-show="showMore"
                      v-model="searchInfo.condition"
                      :items="conditions"
                      class="rounded-pill"
                      label="Condition"
                      placeholder="Condition"
                      solo
                  />
                  <v-text-field
                      v-show="showMore"
                      v-model="searchInfo.address"
                      class="rounded-pill mr-1"
                      label="Address"
                      placeholder="Address"
                      solo
                  />
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="searchInfo.city"
                          class="rounded-pill ml-1"
                          label="City"
                          placeholder="City"
                          solo
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="searchInfo.postcode"
                          class="rounded-pill ml-1"
                          label="Postcode"
                          placeholder="Postcode"
                          solo
                      />
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="searchInfo.state"
                          :items="states"
                          class="rounded-pill ml-1"
                          label="State"
                          placeholder="State"
                          solo
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-btn
                class="white--text"
                color="primary"
                type="submit"
                width="250px"
                @click="getSearchInfo"
            >Search
            </v-btn
            >
            <v-spacer></v-spacer>
            <v-btn class="white--text mr-2" outlined @click="showMore = !showMore">{{
                showMore ? "- Less Filters" : "+ More Filters"
              }}
            </v-btn>
            <v-btn class="white--text" outlined @click="cancelSearch()"
            >Cancel Search
            </v-btn
            >
          </div>
        </v-form>
      </v-col>
      <v-col></v-col>
    </v-row>

    <!-- Mobile & Tablet View -->
    <v-row
        v-show="$vuetify.breakpoint.mdAndDown"
        :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-10'"
        class="secondary"
        no-gutters
    >
      <v-col>
        <v-text-field
            v-model="searchInfo.name"
            append-icon="mdi-magnify"
            class="rounded-pill"
            placeholder="Search by Name"
            solo
            type="text"
        />
        <v-combobox
            v-model="searchInfo.brand"
            :items="brandItems"
            class="rounded-pill"
            placeholder="Vehicle Manufacturer"
            solo
        />
        <v-combobox
            v-model="searchInfo.model"
            :items="modelItems"
            class="rounded-pill"
            placeholder="Model"
            solo
        />
        <v-select
            v-show="showMore"
            v-model="searchInfo.type"
            :items="types"
            class="rounded-pill"
            placeholder="Type"
            solo
        />
        <v-select
            v-model="searchInfo.tags"
            :items="tagItems"
            class="rounded-pill"
            label="Category"
            solo
        />
        <v-combobox
            v-show="showMore"
            v-model="searchInfo.maker"
            :items="manufacturerItems"
            class="rounded-pill"
            placeholder="Manufacturer"
            solo
        />
        <v-text-field
            v-show="showMore"
            v-model="searchInfo.address"
            class="rounded-pill"
            placeholder="Address"
            solo
        />
        <v-text-field
            v-show="showMore"
            v-model="searchInfo.city"
            class="rounded-pill"
            placeholder="City"
            solo
        />
        <v-row v-show="showMore" no-gutters>
          <v-col cols="6">
            <v-text-field
                v-model="searchInfo.postcode"
                class="mr-1 rounded-pill"
                placeholder="Postcode"
                solo
                type="number"
            />
          </v-col>
          <v-col cols="6">
            <v-select
                v-model="searchInfo.state"
                :items="states"
                class="ml-1 rounded-pill"
                placeholder="State"
                solo
                type="text"
            />
          </v-col>
        </v-row>
        <v-select
            v-show="showMore"
            v-model="searchInfo.year"
            :items="getYears"
            class="rounded-pill"
            placeholder="Year"
            solo
        />
        <v-select
            v-show="showMore"
            v-model="searchInfo.condition"
            :items="conditions"
            class="rounded-pill"
            placeholder="Condition"
            solo
        />
        <v-row v-show="showMore" no-gutters>
          <v-col cols="6">
            <v-text-field
                v-model="priceMin"
                class="mr-1 rounded-pill"
                placeholder="Min $"
                solo
                type="number"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="priceMin"
                class="ml-1 rounded-pill"
                placeholder="Max $"
                solo
                type="number"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-btn block class="white--text mb-2" outlined @click="showMore = !showMore">{{
                showMore ? "- Less Filters" : "+ More Filters"
              }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block class="white--text mb-2 float-right" outlined @click="cancelSearch()"
            >Cancel Search
            </v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
                block
                class="white--text mb-2"
                color="primary"
                @click="getSearchInfo"
            >Search
            </v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      searchAll: "",
      total: "",
      searchInfo: {
        name: "",
        brand: "",
        model: "",
        maker: "",
        year: "",
        type: "",
        condition: "",
        address: "",
        city: "",
        postcode: "",
        state: "",
        tags: "",
      },
      priceMin: "",
      priceMax: "",
      showMore: false,
      brandItems: [],
      modelItems: [],
      manufacturerItems: [],
      tagItems: [],
      states: ["NSW", "VIC", "ACT", "WA", "NT", "SA", "QLD", "TAS"],
      conditions: ["New", "Used", "Poor"],
      types: ["All", "Sedan", "Hatchback", "Ute"],
    };
  },
  mounted() {
    this.getBrands();
    this.getModels();
    this.getMakers();
    this.getTagsCall();
  },
  computed: {
    getYears() {
      let _years = [];
      let date = new Date().getFullYear();
      for (let year = date; year >= 1900; year--) {
        _years.push(year);
      }
      return _years;
    },
  },
  methods: {
    ...mapActions('data', ["getData", "postData"]),
    async getSearchInfo() {
      this.searchAll = this.searchAll.replace(/\s/g, "_");
      this.total += this.searchAll;
      for (let key in this.searchInfo) {
        if (this.searchInfo[key] !== "") {
          this.total += `_${this.searchInfo[key]}`;
        }
      }
      // check for _ at start of total, trim if there
      if (this.total.charAt(0) === "_") {
        this.total = this.total.substring(1);
      }
      // check for _ at end of total, trim if there
      if (this.total.charAt(this.total.length - 1) === "_") {
        this.total = this.total.substring(0, this.total.length - 1);
      }
      // add priceMin and priceMax to total seperated with +
      if (this.priceMin !== "" || this.priceMax !== "") {
        // check if priceMin or priceMax is empty, if so add 0
        if (this.priceMin === "") {
          this.priceMin = 0;
        }
        if (this.priceMax === "") {
          this.priceMax = 999999;
        }
        this.total += `-${this.priceMin}_${this.priceMax}`;
      }
      // emitt total to parent
      this.$emit("search", this.total);
      // change route query to total
      await this.$router.push({query: {search: this.total}});
      // reset total
      this.resetInfo();
    },
    async getModels() {
      let response = await this.getData({
        url: "getModels",
      });
      if (response["error"] !== "none" || response["error"] === undefined) {
        console.error("Error: " + response["error"]);
      } else if (response["error"] === "none") {
        this.modelItems = response["models"];
      }
    },
    async getBrands() {
      let response = await this.getData({
        url: "getBrands",
      });
      if (response["error"] !== "none" || response["error"] === undefined) {
        console.error("Error: " + response["error"]);
      } else if (response["error"] === "none") {
        this.brandItems = response["brands"];
      }
    },
    async getMakers() {
      let response = await this.getData({
        url: "getMakers",
      });
      if (response["error"] !== "none" || response["error"] === undefined) {
        console.error("Error: " + response["error"]);
      } else if (response["error"] === "none") {
        this.manufacturerItems = response["makers"];
      }
    },
    async getTagsCall() {
      let response = await this.getData({
        url: "getTags",
      });
      if (response["error"] !== "none" || response["error"] === undefined) {
        console.error("Error: " + response["error"]);
      } else if (response["error"] === "none") {
        this.tagItems = response["tags"];
      }
    },
    resetInfo() {
      this.total = "";
      this.searchAll = ""
      this.searchInfo = {
        name: "",
        brand: "",
        model: "",
        maker: "",
        year: "",
        type: "",
        condition: "",
        priceMin: "",
        priceMax: "",
        address: "",
        city: "",
        postcode: "",
        state: "",
        tags: "",
      };
    },
    cancelSearch() {
      this.resetInfo();
      this.$emit("cancelSearch");
      this.$router.push({query: {}});
    },
  },
};
</script>

<style>
.backgroundImg {
  background: url("../assets/background.svg") center center;
  background-size: 10vw;
  background-blend-mode: multiply;
}
</style>