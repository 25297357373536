<template>
  <div>
    <!-- Mobile View -->
    <v-card v-if="$vuetify.breakpoint.mobile" class="pa-6" color="secondary">
      <h4 class="text-h4 white--text">View by</h4>
      <h3 class="text-h3 primary--text font-weight-bold mb-6">{{ title }}</h3>
      <v-row v-if="parts.length > 0">
        <v-col cols="6"
        >
          <PartIcon v-if="parts[0]" :user="parts[0]" class="my-2" height="150px"
          />
          <PartIcon v-if="parts[1]" :user="parts[1]" class="my-2" height="150px"
          />
          <PartIcon v-if="parts[2]" :user="parts[2]" class="my-2" height="150px"
          />
        </v-col>
        <v-col cols="6"
        >
          <PartIcon v-if="parts[3]" :user="parts[3]" class="my-2" height="150px"/>
          <PartIcon v-if="parts[4]" :user="parts[4]" class="my-2" height="150px"/>
          <v-btn
              v-if="!disabledMore"
              block
              class="primary--text my-2"
              height="150px"
              @click="$router.push(push)"
          >View more
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn
          >
          <PartIcon v-if="disabledMore && parts[5]" :user="parts[5]" class="my-2" height="150px"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <h4 class="text-h4 white--text my-6">No Items listed</h4>
      </v-row>
    </v-card>
    <!-- Desktop View -->
    <v-card v-else color="secondary">
      <v-row class="pa-6">
        <v-col cols="4">
          <h4 class="text-h4 white--text">View by</h4>
          <h3 class="text-h3 primary--text font-weight-bold">{{ title }}</h3>
        </v-col>
        <v-col>
          <v-row v-if="loaded === 1">
            <v-col cols="2"
            >
              <PartIcon v-if="parts[0]" :user="parts[0]" height="150px"
              />
            </v-col>
            <v-col cols="2"
            >
              <PartIcon v-if="parts[1]" :user="parts[1]" height="150px"
              />
            </v-col>
            <v-col cols="2"
            >
              <PartIcon v-if="parts[2]" :user="parts[2]" height="150px"
              />
            </v-col>
            <v-col cols="2"
            >
              <PartIcon v-if="parts[3]" :user="parts[3]" height="150px"
              />
            </v-col>
            <v-col cols="2"
            >
              <PartIcon v-if="parts[4]" :user="parts[4]" height="150px"
              />
            </v-col>
            <v-col cols="2"
            >
              <v-btn
                  v-if="!disabledMore"
                  block
                  class="primary--text"
                  height="150px"
                  @click="$router.push(push)"
              >View more
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn
              >
              <PartIcon v-if="disabledMore && parts[5]" :user="parts[5]" height="150px"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="loaded === 0">
            <v-col>
              <Loader :size="75"/>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <h4 class="text-h4 white--text my-6">An error has occurred, try refreshing the page</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import PartIcon from "@/components/landing/PartIcon.vue";
import {mapActions} from 'vuex';
import Loader from "@/components/Loader";

export default {
  components: {Loader, PartIcon},
  props: ["title", "type", "push", "disabledMore"],
  data() {
    return {
      parts: [],
      loaded: 0
    };
  },
  mounted() {
    this.fetchParts();
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async fetchParts() {
      let response = await this.postData({
        url: this.type,
        data: {start: 0, limit: 5},
      });
      try {
        response["users"].forEach((element) => {
          this.parts.push(element);
        });
        this.loaded = 1;
      } catch (error) {
        console.log("No Parts found: " + error);
        this.loaded = 2;
      }
    },
  },
};
</script>