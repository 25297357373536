<!-- This part card differs as it is used with OtherParts.vue with different props removing "injected"-->
<template>
  <!--  <v-skeleton-loader-->
  <!--      :loading="loading"-->
  <!--      type="image, article, actions"-->
  <!--  >-->
  <v-card
      class="mx-auto"
      elevation="6"
      height="100%"
      width="100%"
      @click="
      $router.push({
        name: 'Part',
        query: { id: item.partsID, name: item.partsName },
      })
    "
  >
    <div class="pt-3 px-3 mx-auto">
      <v-img
          :src="imageName"
          aspect-ratio="1"
          class="secondary rounded"
          height="200px"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="d-flex flex-column pa-5">
      <div class="titleSize secondary--text text-h4 font-weight-bold">
        <h5 class="text-h5 font-weight-medium">
          {{ name }}
        </h5>
      </div>
      <div>
        <v-divider class="my-3"></v-divider>
        <div>
          <div class="grey lighten-2 pa-2 my-1 rounded">
            <h5 class="text-h5">
              Selling for
              <span class="primary--text font-weight-bold"
              >${{ item.partsPrice }}</span
              >
            </h5>
            <p
                class="overflow-y-hidden mb-n1"
                style="height: 25px; word-break: break-word"
            >
              {{ location }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-card>
  <!--  </v-skeleton-loader>-->
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  props: ["item"],
  data() {
    return {
      images: [{partsImgStr: ""}],
      oneImg: false,
      loading: true,
      imageLoading: true,
    };
  },
  updated() {
  },
  mounted() {
    this.getImages();
    if (this.item.injected !== {} || this.item.injected !== null) {
      this.loading = false;
    }
    if (this.images !== [{partsImgStr: "default.webp"}]) {
      this.imageLoading = false;
    }
  },
  computed: {
    ...mapState(["baseURL"]),
    location() {
      if (
          this.item.partsAddress &&
          this.item.partsCity &&
          this.item.partsPostcode &&
          this.item.partsState
      ) {
        return this.item.partsCity +
            ", " +
            this.item.partsPostcode +
            ", " +
            this.item.partsState;
      } else {
        return "Location Unknown";
      }
    },
    name() {
      let str = this.item.partsName.toUpperCase();
      const tol = 25;
      if (str.length > tol) {
        const diff = str.length - tol;
        str = str.slice(0, -diff);
        str += "...";
      }
      return str;
    },
    imageName() {
      return (
          this.baseURL +
          "/storage/partImgs/" +
          this.images[0]["partsImgStr"].split(".webp")[0] +
          "Lazy.webp"
      );
    }
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async getImages() {
      let response = await this.postData({
        url: "fetchPartImg",
        data: {id: this.item.partsID},
      });
      if (response["error"] !== "none") {
        this.oneImg = true;
      } else {
        this.images = response["images"];
        if (this.images.length <= 1) {
          this.oneImg = true;
        }
      }
    },
  },
};
</script>

<style>
.titleSize {
  height: 64px;
  max-height: 64px;
  overflow: hidden;
}
</style>