<template>
  <v-card>
    <!-- Mobile -->
    <v-row v-if="$vuetify.breakpoint.mobile" class="pa-8">
      <v-col>
        <h4 class="text-h4">View by</h4>
        <h3 class="text-h3 primary--text font-weight-bold">Category</h3>
        <v-row class="mt-4">
          <v-col>
            <v-btn block class="my-2" height="65px" @click="toSearch('Car Parts')"
            >Car Parts
            </v-btn
            >
            <v-btn block class="my-2" height="65px" @click="toSearch('Truck Parts')"
            >Truck Parts
            </v-btn
            >
            <v-btn
                block
                class="my-2"
                height="65px"
                @click="toSearch('Speedway')"
            >Speedway
            </v-btn
            >
            <v-btn
                block
                class="my-2"
                height="65px"
                @click="toSearch('Vintage & Classic')"
            >Vintage & Classic
            </v-btn
            >
            <v-btn
                block
                class="my-2"
                height="65px"
                @click="toSearch('Rally & Off Road')"
            >Rally & Off Road
            </v-btn
            >
            <v-btn
                block
                class="my-2"
                height="65px"
                @click="toSearch('Wheels & Tyres')"
            >Wheels & Tyres
            </v-btn
            >
            <v-btn block class="my-2" height="65px" @click="toSearch('Complete Car')"
            >Complete Car
            </v-btn
            >
            <v-btn block class="my-2" height="65px" @click="toSearch('Auto Consumables')"
            >Auto Consumables
            </v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Desktop -->
    <v-row v-else class="pa-8">
      <v-col cols="3">
        <h4 class="text-h4">View by</h4>
        <h3 class="text-h3 primary--text font-weight-bold">Category</h3>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="3">
            <v-btn block @click="toSearch('Car Parts')">Car Parts</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Truck Parts')">Truck Parts</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Speedway')">Speedway</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Vintage & Classic')">Vintage & Classic</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-btn block @click="toSearch('Rally & Off-road')">Rally & Off-road</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Wheels & Tyres')">Wheels & Tyres</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Complete Car')">Complete Car</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block @click="toSearch('Auto Consumables')"
            >Auto Consumables
            </v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  methods: {
    toSearch(param) {
      const encodedParam = encodeURIComponent(param);
      this.$router.push({name: "Market", query: {search: encodedParam}});
    },
  },
};
</script>