import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async createNewPart({commit, rootState}, formObj) {
            let formData = new FormData();
            for (let element in formObj["data"]) {
                formData.append(element, formObj["data"][element]);
            }
            for (let element in formObj["images"]) {
                formData.append(element, formObj["images"][element]);
            }
            let response = await axios
                .post(
                    rootState.baseURL + "/php/parts/createNewPart.inc.php",
                    formData
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
        async deletePart({commit, rootState}, formObj) {
            let formData = new FormData();
            console.log(formObj);
            formData.append("partsID", formObj["partsID"]);
            formData.append("usersID", rootState.user.currentUser.usersID);
            formData.append("usersPwd", rootState.user.currentUser.usersPwd);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/parts/deletePart.inc.php",
                    formData
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
        async editPart({commit, rootState}, formObj) {
            let formData = new FormData();
            for (let element in formObj["data"]) {
                formData.append(element, formObj["data"][element]);
            }
            for (let element in formObj["images"]) {
                formData.append(element, formObj["images"][element]);
            }
            formData.append("tags", formObj["tags"]);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/parts/editPart.inc.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
    },
};
