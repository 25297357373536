import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async getData({commit, rootState}, formObj) {
            let url =
                rootState.baseURL + "/php/data/get/" +
                formObj["url"] +
                ".inc.php";
            let response = await axios.get(url);
            return response.data;
        },
        async postData({commit, rootState}, formObj) {
            let url =
                rootState.baseURL + "/php/data/fetch/" +
                formObj["url"] +
                ".inc.php";
            let formData = new FormData();
            for (let element in formObj["data"]) {
                formData.append(element, formObj["data"][element]);
            }
            let response = await axios
                .post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .catch((e) => {
                    console.log(e);
                });
            return response.data;
        },
    },
};
