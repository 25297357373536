<template>
  <div class="d-flex justify-center align-center my-16">
    <v-progress-circular :size="size" color="primary" indeterminate/>
  </div>
</template>

<script>
export default {
  props: ["size"],
};
</script>