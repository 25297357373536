<template>
  <v-card :height="height" class="pa-2" @click="action">
    <v-img :src="img" height="100%" width="100%"/>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  props: ["part", "user", "height"],
  data() {
    return {
      img: "",
    };
  },
  computed: {
    ...mapState(["baseURL"]),
  },
  mounted() {
    if (this.user === null || this.user === "" || this.user === undefined) {
      this.fetchImg();
    } else {
      this.img =
          this.baseURL + "/storage/pfps/" + this.user.usersPfp;
    }
  },
  methods: {
    ...mapActions('data', ["postData"]),
    async fetchImg() {
      let response = await this.postData({
        url: "fetchPartImg",
        data: {id: this.part.partsID},
      });
      this.img =
          this.baseURL +
          "/storage/partImgs/" +
          response["images"][0]["partsImgStr"];
    },
    action() {
      if (this.user === null || this.user === "" || this.user === undefined) {
        this.$router.push({
          name: "Part",
          query: {id: this.part.partsID, name: this.part.partsName},
        });
      } else {
        this.$router.push({
          name: "Profile",
          params: {uid: this.user.usersUid},
        });
      }
    },
  },
};
</script>