import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async newSubCheckout({rootState}, priceId) {
            let response = await axios
                .post(
                    rootState.baseURL + "/php/subscriptions/create-checkout-session.php",
                    {priceId: priceId, user_email: rootState.user.currentUser.usersEmail}
                )
                .catch((e) => {
                    console.log(e);
                });
            if (response.data["url"] === undefined) {
                console.log("Error: " + response.data["error"]);
                return response;
            }
            window.location.href = response.data["url"];
            return response;
        },
        // This handles management and cancellations
        async manageSub({rootState}) {
            let formData = new FormData();
            formData.append("user_email", rootState.user.currentUser.usersEmail);
            formData.append("customer_id", rootState.user.currentUser.customer_id);
            let response = await axios
                .post(
                    rootState.baseURL + "/php/subscriptions/create-portal-session.php",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .catch((e) => {
                    console.log(e);
                });
            window.location.href = response.data["url"];
            return response;
        }
    },
};
