<template>
  <div>
    <v-btn :block="block" @click="sheet = !sheet">Report</v-btn>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" height="400px">
        <v-btn class="mt-6" color="primary" text @click="sheet = !sheet">
          close
        </v-btn>
        <div class="py-3 mx-12">
          <h2 class="pa-2 primary--text">Report a part</h2>
          <h3>You are placing a report on the item "{{ part.partsName }}"</h3>
          <div>
            <v-text-field
                v-model="reportData.message"
                placeholder="(Optional) Enter a reason"
            />
          </div>
        </div>
        <v-btn class="white--text" color="primary" @click="report">Report</v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  props: ["part", "block"],
  data() {
    return {
      sheet: false,
      reportData: {
        partsID: "",
        receiving: "",
        sending: "",
        message: "",
      },
    };
  },
  mounted() {
    this.reportData.partsID = this.part.partsID;
  },
  methods: {
    ...mapActions('alert', ["alerts"]),
    ...mapActions('user', ["report", "pwdCheck"]),
    async report() {
      if (await this.pwdCheckCall() === true) {
        let response = await this.report(this.reportData);
        if (response["error"] === "none") {
          await this.alerts({
            type: "success",
            msg: "Report successfully created!",
          });
        } else if (response["error"] === "reportExists") {
          await this.alerts({
            type: "error",
            msg: "You have already filed a report for that user",
          });
        }
      }
    },
    async pwdCheckCall() {
      let response = await this.pwdCheck();
      if (response["error"] === "userNotFound") {
        await this.alerts({
          type: "error",
          msg: "We had trouble finding the user, make sure you are logged in, or login again",
        });
      } else if (response["error"] === "stmtFailed") {
        await this.alerts({
          type: "error",
          msg: "It seems something has failed on our end, try again or at a later time",
        });
      } else if (response["error"] === "noPostData") {
        await this.alerts({
          type: "error",
          msg: "There was an issue sending data, please try again or login again",
        });
      }
      if (response["correct"] === true) {
      } else if (response["correct"] === false) {
        await this.$router.push({name: "Login"});
        await this.alerts({
          type: "error",
          msg: "You must be logged in to access this page.",
        });
      }
    },
  },
};
</script>