import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {
        alertMsg: "An error has occurred",
        alertType: "error",
        alertOn: false,
    },
    mutations: {
        SET_ALERT(state, alert) {
            state.alertMsg = alert["msg"];
            state.alertType = alert["type"];
            state.alertOn = true;
        },
        OFF_ALERT(state) {
            state.alertOn = false;
        },
    },
    actions: {
        // Turn the Alert on
        alerts({commit}, alert) {
            commit("SET_ALERT", alert);
        },
        // Turn the Alert off
        offAlert({commit}) {
            commit("OFF_ALERT");
        },
    },
};
