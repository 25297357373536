<template>
  <v-app>
    <NavBar/>
    <v-main class="pt-16">
      <Alert/>
      <transition mode="out-in" name="slide">
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Alert from '@/components/Alert.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  components: {NavBar, Alert},
  created() {
    this.$store.dispatch("user/loadUser");
    console.log(`%cWARNING: THIS PANEL IS INTENDED FOR DEVELOPERS, IF SOMEONE TOLD YOU TO COME HERE DO NOT CONTINUE`, "color: red; font-weight: 1000; font-size: 20px");
  }
};
</script>

<style>
#app {
  background: url("./assets/background.svg") center center;
  background-size: 25vw;
}
.pointerG {
  cursor: pointer;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 400ms;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-150%);
}
</style>
