<template>
  <div>
    <!-- Mobile Tablet View -->
    <div v-if="$vuetify.breakpoint.mdAndDown">
      <v-card
          class="mx-auto pa-2 rounded-lg mt-2"
          color="secondary"
          width="95%"
      >
        <v-carousel
            :hide-delimiters="images.length < 1"
            :show-arrows="images.length > 1"
            class="secondary"
            height="340"
            hide-delimiter-background
        >
          <v-carousel-item
              v-for="(item, i) in images"
              :key="i"
              :src="baseURL + '/storage/partImgs/' + item.partsImgStr"
          />
        </v-carousel>
      </v-card>
      <v-card
          class="mx-auto my-3 rounded-lg pa-3"
          color="grey lighten-3"
          width="95%"
      >
        <v-card-title>
          <h3 class="text-h3 font-weight-bold">
            {{ partInfo.partsName }}
            <span v-if="partInfo.partsPrice == 0 || partInfo.partsPrice == '0'" class="primary--text font-weight-bold"
            >Free</span
            >
            <span v-else class="primary--text font-weight-bold"
            >${{ partInfo.partsPrice }}</span
            >
          </h3>
          <h4 class="text-h4">Sold by <span
              class="primary--text pointerG"
              @click="$router.push({ name: 'Profile', params: { uid: partInfo.usersUid } })">{{ partInfo.usersUid }}</span></h4>
        </v-card-title>
        <v-card-text>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Brand:</span>
            <br/>
            <v-chip
                v-for="model in partInfo.partsBrand"
                :key="model"
                class="mr-1"
            >
              {{ model }}
            </v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Model:</span>
            <br/>
            <v-chip
                v-for="model in partInfo.partsModel"
                :key="model"
                class="mr-1"
            >
              {{ model }}
            </v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Type:</span>
            <br/>
            <v-chip>{{ partInfo.partsType }}</v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Manufacturer:</span>
            <br/>
            <v-chip>{{ partInfo.partsMaker }}</v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Condition:</span>
            <br/>
            <v-chip>{{ partInfo.partsCondition }}</v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Location:</span>
            <br/>
            <v-chip>{{ location }}</v-chip>
          </p>
          <p class="body-1">
            <span class="text-h5 font-weight-bold mr-1">Description:</span>
            <br/>
            <div class="grey lighten-2 rounded-xl px-4 py-3 text--black">{{ partInfo.partsDesc }}</div>
          </p>
        </v-card-text>
        <v-card-text>
          <SellerChip :usersUid="partInfo.usersUid" class="mb-6"/>
          <Report :block="true" :part="partInfo"/>
        </v-card-text>
      </v-card>
      <OtherParts v-if="partInfo.usersUid !== ''" :uid="partInfo.usersUid"/>
    </div>

    <!-- Desktop View -->
    <div v-else>
      <v-card class="pa-5 mx-auto" flat width="95%">
        <v-row class="d-flex align-stretch">
          <v-col cols="6" class="d-flex flex-grow-1">
            <v-carousel
                :hide-delimiters="images.length <= 1"
                :show-arrows="images.length > 1"
                class="secondary rounded-lg"
                hide-delimiter-background
                height="700"
            >
              <v-carousel-item v-for="(item, i) in images" :key="i" contain>
                <v-img
                    :src="baseURL + '/storage/partImgs/' + item.partsImgStr"
                    contain
                    height="100%"
                />
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col class="flex-grow-1">
            <v-card class="rounded-lg pa-6" color="grey lighten-3" height="700">
              <div>
                <h3 class="text-h3 font-weight-bold word-wrapper mb-4">
                  {{ partInfo.partsName }}
                  <span class="primary--text font-weight-bold"
                  >${{ partInfo.partsPrice }}</span
                  >
                </h3>
                <h5 class="text-h5 mb-4">Sold by <span
                    class="primary--text pointerG"
                    @click="$router.push({ name: 'Profile', params: { uid: partInfo.usersUid } })">{{ partInfo.usersUid }}</span></h5>
              </div>
              <v-row>
                <v-col>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1">Brand:</span>
                    <br/>
                    <v-chip
                        v-for="brand in partInfo.partsBrand"
                        v-if="brand !== ''"
                        :key="brand"
                        class="mr-1"
                        @click="$router.push({name: 'Search', query: {name: '', brand: brand, model: '', maker: '', year: '', type: '', condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})"
                    >
                      {{ brand }}
                    </v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1">Model:</span>
                    <br/>
                    <v-chip
                        v-for="model in partInfo.partsModel"
                        v-if="model !== ''"
                        :key="model"
                        class="mr-1"
                        @click="$router.push({name: 'Search', query: {name: '', brand: '', model: model, maker: '', year: '', type: '', condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})"
                    >
                      {{ model }}
                    </v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1"
                    >Car Year:</span
                    >
                    <br/>
                    <v-chip v-if="partInfo.partsYear !== ''" @click="$router.push({name: 'Search', query: {name: '', brand: '', model: '', maker: '', year: partInfo.partsYear, type: '', condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})">{{ partInfo.partsYear }}</v-chip>
                    <v-chip v-if="partInfo.partsYear === ''">Not Provided</v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1">Type:</span>
                    <br/>
                    <v-chip v-if="partInfo.partsType !== ''" @click="$router.push({name: 'Search', query: {name: '', brand: '', model: '', maker: '', year: '', type: partInfo.partsType, condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})">{{ partInfo.partsType }}</v-chip>
                    <v-chip v-if="partInfo.partsType === ''">Not Provided</v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1"
                    >Manufacturer:</span
                    >
                    <br/>
                    <v-chip v-if="partInfo.partsMaker !== ''" @click="$router.push({name: 'Search', query: {name: '', brand: '', model: '', maker: partInfo.partsMaker, year: '', type: '', condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})">{{ partInfo.partsMaker }}</v-chip>
                    <v-chip v-if="partInfo.partsMaker === ''">Not Provided</v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1"
                    >Condition:</span
                    >
                    <br/>
                    <v-chip v-if="partInfo.partsCondition !== ''" @click="$router.push({name: 'Search', query: {name: '', brand: '', model: '', maker: '', year: '', type: '', condition: partInfo.partsCondition, priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: ''}})">{{ partInfo.partsCondition }}</v-chip>
                    <v-chip v-if="partInfo.partsCondition === ''">Not Provided</v-chip>
                  </p>
                </v-col>
                <v-col cols="12" md="8" lg="8">
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1">Location:</span>
                    <br/>
                    <v-chip>{{ location }}</v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1"
                    >Description:</span
                    >
                    <br/>
                    <div class="grey lighten-2 rounded-xl px-4 py-3 body-2" v-if="partInfo.partsDesc !== ''">{{
                        partInfo.partsDesc
                      }}
                    </div>
                    <v-chip v-if="partInfo.partsDesc === ''">Not Provided</v-chip>
                  </p>
                  <p class="body-1">
                    <span class="text-h5 font-weight-bold mr-1"
                    >Categories:</span
                    >
                    <br/>
                    <v-chip
                        v-for="tag in partInfo.partsTags"
                        v-if="tag !== ''"
                        :key="tag"
                        class="mr-1 mt-2"
                        @click="$router.push({name: 'Search', query: {name: '', brand: '', model: '', maker: '', year: '', type: '', condition: '', priceMin: 0, priceMax: 99999, address: '', city: '', postcode: '', state: '', tags: tag}})"
                    >{{ tag }}
                    </v-chip
                    >
                  </p>
                  <SellerChip :usersUid="partInfo.usersUid" class="mb-6"/>
                  <Report :block="true" :part="partInfo"/>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <OtherParts v-if="partInfo.usersUid !== ''" :uid="partInfo.usersUid"/>
      </v-card>
    </div>
  </div>
</template>

<script>
import PartIcon from "@/components/landing/PartIcon.vue";
import SellerChip from "@/components/SellerChip.vue";
import Report from "@/components/Report.vue";
import {mapActions, mapState} from "vuex";
import OtherParts from "@/components/Profile/OtherParts";

export default {
  components: {OtherParts, SellerChip, Report, PartIcon},
  metaInfo: {
    title: "Viewing Part",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  data() {
    return {
      partInfo: {
        partsID: 0,
        usersUid: "",
        usersID: "",
        partsName: "",
        partsDesc: "",
        partsModel: [],
        partsYear: "",
        partsType: "",
        partsBrand: [],
        partsMaker: "",
        partsCondition: "",
        partsPrice: 0,
        partsAddress: "",
        partsCity: "",
        partsPostcode: "",
        partsState: "",
      },
      images: [],
      userParts: [],
    };
  },
  computed: {
    ...mapState(["baseURL"]),
    location() {
      if (
          this.partInfo.partsAddress &&
          this.partInfo.partsCity &&
          this.partInfo.partsPostcode &&
          this.partInfo.partsState
      ) {
        return this.partInfo.partsAddress +
            ", " +
            this.partInfo.partsCity +
            ", " +
            this.partInfo.partsPostcode +
            ", " +
            this.partInfo.partsState;
      } else {
        return "Location Unknown";
      }
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.getPartInfo();
        this.getImages();
      },
    },
  },
  methods: {
    ...mapActions("data", ["postData"]),
    ...mapActions("alert", ["alerts"]),
    async getPartInfo() {
      let response = await this.postData({
        url: "fetchPart",
        data: {partsID: this.$route.query.id},
      });
      if (response["error"] === "none" || response["error"] === undefined) {
        this.partInfo = response["parts"][0];
        console.log(response)
        await this.getUserParts();
        this.splitPartArrays();
      } else if (response["error"] === "noPostData") {
        this.alerts({
          type: "error",
          msg: "There was an error sending the data.",
        });
      } else if (response["error"] === "stmtFailed") {
        this.alerts({
          type: "warning",
          msg: "There was a data collection error.",
        });
      } else if (response["error"] === "partNotFound") {
        this.alerts({
          type: "error",
          msg: "It seems that part was not found.",
        });
      }
    },
    async getImages() {
      let response = await this.postData({
        url: "fetchPartImg",
        data: {id: this.$route.query.id},
      });
      if (response["error"] === "none" || response["error"] === undefined) {
        console.log(response["images"]);
        this.images = response["images"];
      } else if (response["error"] === "noPostData") {
        await this.alerts({
          type: "error",
          msg: "There was an error sending the data.",
        });
      } else if (response["error"] === "stmtFailed") {
        await this.alerts({
          type: "warning",
          msg: "There was a data collection error.",
        });
      } else if (response["error"] === "imagesNotFound") {
        await this.alerts({
          type: "error",
          msg: "It seems that part was not found.",
        });
      }
    },
    async getUserParts() {
      let response = await this.postData({
        url: "fetchUserParts",
        data: {usersID: this.partInfo.usersID, start: 0, limit: 7},
      });
      if (response["error"] === "none") {
        if (response["parts"] === "none") {
        } else {
          this.userParts = response["parts"];
        }
      } else if (response["error"] !== "none") {
      }
    },
    splitPartArrays() {
      this.partInfo.partsBrand = this.partInfo.partsBrand.split(",");
      this.partInfo.partsModel = this.partInfo.partsModel.split(",");
      this.partInfo.partsTags = this.partInfo.partsTags.split(",");
    },
  },
};
</script>